@mixin icon($icon) {
    content: $icon;
    font-family: $icons-font-name, sans-serif;
}

@mixin reboot-table() {
    table, thead, tfoot, tbody, tr, td {
        display: block;
    }
}
