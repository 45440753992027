h1 {
  font-family: Rothek, sans-serif;
}

.container {
  padding: 0 20px;
  width: 100%;

  @include breakpoint($xs-max) {
    padding: 0 10px;
  }
}

img {
  max-width: 100%;
}

.btn, a.btn {
  @extend %reboot-a;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  min-height: 40px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 30px;
  border-radius: 4px;

  &-red {
    &, &:hover {
      color: white;
      background-color: $red;
    }
  }
}

.cart-informer-block {
  max-height: 100px;
  transition: .5s;

  &.empty {
    max-height: 0;
  }
}

.total-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  padding: 20px;
  color: white;

  &__text {
    font-weight: 250;
    font-size: 12px;
    letter-spacing: 0.1em;
  }

  &__price {
    font-weight: 700;
    font-size: 32px;
    line-height: 1;
    white-space: nowrap;
  }

  &__success {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: right;
  }

  .btn {
    max-width: 170px;
  }
}

.page {
  &-content {
    grid-area: CONTENT;
    overflow-x: hidden;
    overflow-y: auto;

    &-wrapper {
      display: flex;
      min-height: 100%;
    }

    &.blue {
      background: url("/design/qr_menu/images/bg-blue.jpg") no-repeat center center;
      background-size: cover;
      color: white;
    }
  }

  &-box {
    display: grid;
    grid-template-areas:
      "HEADER"
      "CONTENT"
      "FOOTER";
    grid-template-rows: max-content auto max-content;
    grid-template-columns: 100%;
    width: 100%;
    height: 100%;
    max-width: 768px;
    max-height: 1024px;
    background-color: #fff;
    box-shadow: 0 0 5px 0 #dfdfdf;
    overflow: hidden;
  }

  &-wrapper {
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    background-color: #F5F5F5;
    overflow: hidden;
  }
}
