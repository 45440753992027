$icons-font-name: "Icons" !default;
$icons-font-path: "/design/qr_menu/fonts/Icons/" !default;

$icon-arrow-left: "\ea01";
$icon-minus: "\ea02";
$icon-plus: "\ea03";

@font-face {
  font-display: block;
  font-family: "Icons";
  font-style: normal;
  font-weight: normal;
  src: url("#{$icons-font-path}#{$icons-font-name}.eot?1662455182256");
  src: url("#{$icons-font-path}#{$icons-font-name}.eot?#iefix") format("embedded-opentype"), url("#{$icons-font-path}#{$icons-font-name}.woff2?1662455182256") format("woff2"), url("#{$icons-font-path}#{$icons-font-name}.woff?1662455182256") format("woff"), url("#{$icons-font-path}#{$icons-font-name}.ttf?1662455182256") format("truetype"), url("#{$icons-font-path}#{$icons-font-name}.svg?1662455182256#Icons") format("svg");
}

[class^="icon-"], [class*=" icon-"], .icon {
    font-family: "#{$icons-font-name}" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left::before {
    content: $icon-arrow-left;
}
.icon-minus::before {
    content: $icon-minus;
}
.icon-plus::before {
    content: $icon-plus;
}
