.products {
  &-item {
    &__img {
      position: relative;
      min-height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      will-change: transform;

      img {
        width: 100%;
        user-select: none;
        filter: drop-shadow(0px 10px 14px rgba(23, 23, 23, 0.62));
        will-change: transform;
      }
    }

    &__title {
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    &__desc {
      font-weight: 250;
      font-size: 14px;
      letter-spacing: 0.1em;
    }

    &__promo {
      position: absolute;
      max-height: 20px;
      top: 0;
      left: 20px;
      display: block;
    }

    &__price {
      font-weight: 700;
      font-size: 28px;
      line-height: 1;
    }

    &__feature {
      font-weight: 700;
      font-size: 14px;
      line-height: 1;
      align-self: flex-end;
      margin-left: 10px;
    }

    &-price-block {
      grid-area: BLOCK;
      display: flex;
      align-items: center;
      padding: 20px 10px 20px 20px;
      will-change: transform;
    }

    &-content {
      position: relative;
      padding-top: 30px;
    }

    &-amount {
      --size: 30px;

      display: flex;
      padding: 0 10px 0 0;
      height: 100%;
      margin: 0;
      max-width: 40%;
      width: 150px;
      user-select: none;

      &__plus, &__minus {
        display: flex;
        flex: 1 0 0;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: var(--size);

        &:first-of-type {
          padding-left: 10px;
        }

        &:last-of-type {
          padding-right: 10px;
        }
      }

      &__plus {
        &:before {
          @include icon($icon-plus);
        }
      }

      &__minus {
        &:before {
          @include icon($icon-minus);
        }
      }

      &__input {
        display: block;
        flex: 1 0 0;
        background-color: transparent;
        border: none;
        text-align: center;
        font-weight: 700;
        font-size: 22px;
        line-height: 1;
        text-transform: uppercase;
        -moz-appearance: textfield;
        color: inherit;
        max-width: calc(100% - 80px);

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          margin: 0;
          -webkit-appearance: none;
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-images {
      position: relative;
      z-index: 5;

      .swiper {
        z-index: 5;

        &-lazy {
          opacity: 0;
          transition: opacity .4s;

          &-loaded {
            opacity: 1;
          }
        }

        &-slide {
          height: auto;
          padding: 30px 0;
        }

        &-initialized {
          .swiper-lazy-preloader {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 100%;
            border-radius: 100%;
            border-bottom: 0 solid #ffffff05;
            background: rgba(164, 164, 164, 0.52);

            box-shadow:
              0 -10px 20px 20px #ffffff40 inset,
              0 -5px 15px 10px #ffffff50 inset,
              0 -2px 5px #ffffff80 inset,
              0 -3px 2px #ffffffBB inset,
              0 2px 0px #ffffff,
              0 2px 3px #ffffff,
              0 5px 5px #ffffff90,
              0 7px 10px #ffffff60,
              0 8px 12px 12px #ffffff40;
            filter: blur(3px);
            animation: 2s loader-rotate linear infinite;

            @keyframes loader-rotate {
              from {
                transform: scale(1) rotate(0deg);
              }
              50% {
                transform: scale(.95) rotate(180deg);
              }
              to {
                transform: scale(1) rotate(360deg);
              }
            }
          }
        }
      }

      &-logo {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.12;

        .img-wrapper {
          text-align: center;
          height: 100%;
          width: 100%;
          transform: scale(1.4);
        }

        img {
          height: 100%;
          aspect-ratio: 1/1;
        }
      }

      &-inner {
        position: relative;
        transform-style: preserve-3d;
        perspective: 3px;
      }

      &-leaf {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: calc(100% - 60px);
        aspect-ratio: 1/1;
        user-select: none;
        pointer-events: none;

        .leaf {
          position: relative;
          height: 100%;
          width: 100%;
          will-change: transform;

          &-wrapper {
            position: absolute;
            width: 100%;
            max-height: 100%;
          }

          img {
            width: 100%;
            max-height: 100%;
            will-change: transform;
          }
        }

        &.leaf-1 {
          z-index: 6;

          .leaf {
            &-wrapper {
              top: 5%;
              width: 17%;
              left: -2%;
              transform: rotate(35deg);
            }
          }

          img {
            filter: blur(2px);
          }
        }

        &.leaf-2 {
          z-index: 4;

          .leaf {
            &-wrapper {
              top: 50%;
              width: 12%;
              left: 98%;
              transform: translateY(-50%) rotate(-140deg);
            }
          }

          img {
            filter: blur(0.5px);
          }
        }
      }
    }

    &-texts {
      .swiper {
        &-slide {
        }
      }
    }

    &-prices {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: auto;
      overflow: hidden;

      &-wrapper {
        display: grid;
        grid-template-areas: "BLOCK";
      }
    }

    > * {
      position: relative;
      z-index: 10;
    }
  }
}
