.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-area: HEADER;
  padding: 10px 0;
  background-color: #fff;
  box-shadow: 0 11px 11px rgba(0, 0, 0, 0.19);

  &-logo-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__prev {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    font-size: 20px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
  }

  &__logo {
    position: relative;
    max-width: 100%;
    width: 150px;
    overflow: hidden;
    height: 35px;

    svg {
      display: block;
      width: 100%;
      max-height: 100%;
    }

    .img-1 {
      position: relative;
      z-index: 2;
      width: 35px;
      height: 100%;
      margin-right: 5px;
      background-color: #fff;
      padding: 1px; // bugfix. Чтобы при анимации не резались края svg
    }

    .img-2 {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      z-index: 1;
      width: 110px;
      min-width: 110px;
    }
  }

  &-categories {
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    font-weight: 250;
    font-size: 14px;
    text-transform: uppercase;

    a {
      @extend %reboot-a;

      display: block;
      padding: 0 10px;
      width: max-content;
      max-width: calc(100vw - 20px);
    }

    li {
      &.selected {
        a {
          font-weight: 700;

          &, &:hover {
            color: $blue;
          }
        }
      }
    }

    ul {
      @extend %reboot-ul;

      display: flex;
      align-items: center;
      padding: 20px 10px 10px;
    }
  }
}
