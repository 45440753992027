* {
  box-sizing: border-box;
}

@import "service/variables";
@import "service/patterns";
@import "service/breakpoints";

@import "fonts/Icons";

@import "service/mixins";

@import "common/general";

@import "common/header";
@import "common/footer";

@import "common/catalog";
@import "common/products";
@import "common/cart";

@import "common/loader";

body, html {
  margin: 0;
  padding: 0;
  font-family: Rothek, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}
