%reboot {
  &-a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: currentColor;
      text-decoration: none;
    }
  }

  &-ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
    }
  }
}
