.purchases {
  &-item {
    --border: 1px;

    display: flex;

    background: linear-gradient(90deg, #444444 0%, #787878 85%, #444444 100%);
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: var(--border);
    overflow: hidden;
    position: relative;

    > * {
      position: relative;
      z-index: 5;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      top: var(--border);
      left: var(--border);
      width: calc(100% - (var(--border) * 2));
      height: calc(100% - (var(--border) * 2));
      background: url("/design/qr_menu/images/bg-black.jpg") no-repeat left center;
      background-size: cover;
      border-radius: 5px;
    }

    &__img {
      flex: 1 0 0;
      aspect-ratio: 1/1;
      display: block;
      padding: 5px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center center;
      }
    }

    &__name {
      font-weight: 700;
      font-size: 14px;
      line-height: 1.2;
      text-transform: uppercase;
      margin-bottom: 15px;
    }

    &__price {
      font-weight: 700;
      font-size: 22px;
      line-height: 1;
      white-space: nowrap;
    }

    .products-item-amount {
      --size: 25px;
      max-width: 60%;

      @include breakpoint($xs-max) {
        --size: 20px;
      }

      &__minus, &__plus {
        padding: 0;
      }

      &__input {
        max-width: calc(100% - 60px);

        @include breakpoint($xs-max) {
          max-width: calc(100% - 60px);
        }
      }
    }

    &-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: auto;
      width: 100%;
      max-width: 100%;
    }

    &-content {
      --width: 65%;

      flex: 0 0 var(--width);
      max-width: var(--width);
      width: var(--width);

      display: flex;
      flex-direction: column;
      padding: 20px 20px 20px 15px;

      @include breakpoint($xs-max) {
        padding: 10px;
      }
    }
  }

  &-grid {
    padding-top: 20px;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr;
  }

  &-list {
    width: 100%;
    min-height: 100%;
  }
}

.empty-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  min-height: 100%;

  &__text {
    margin-bottom: 20px;
  }

  .btn {
    display: inline-flex;
    max-width: 250px;
  }
}
