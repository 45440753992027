.catalog {
  &-item {
    @extend %reboot-a;

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background: url('/design/qr_menu/images/bg-blue.jpg') no-repeat center center;
    background-size: cover;
    border-radius: 4px;
    box-shadow: 0 2px 3px rgba(0, 21, 48, 0.4);
    padding: 5px;
    color: #fff;
    overflow: hidden;

    > * {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: '';
      display: block;
      background: url("/design/qr_menu/images/logo-figure.svg") no-repeat center center;
      background-size: contain;
      aspect-ratio: 1/1;
      height: 200%;
      position: absolute;
      z-index: 1;
      right: 0;
      top: 50%;
      transform: translate(30%, -50%);
      opacity: 0.13;
    }

    &:hover {
      color: #fff;
    }

    &__name {
      font-weight: 700;
      font-size: 16px;
      line-height: 1.2;
      text-transform: uppercase;
      align-self: flex-end;
      padding: 15px;
    }

    &__img {
      max-height: 80px;
      filter: drop-shadow(0px 15.3143px 15.3143px rgba(0, 0, 0, 0.37));
    }
  }

  &-grid {
    display: grid;
    gap: 10px;
    padding: 20px 0;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
  }
}
