.page-box {
  &.main-loader {
    .header {
      min-height: 100vh;

      &__logo {
        width: 35px;
        transform: scale(2.5);

        .img-1 {
          position: relative;
        }
      }
    }

    &.loading {
      --speed: 1s;

      .header {
        &__logo {
          .img-1 {
            svg {
              transform: scale(0);
              will-change: transform;
              animation:
                loading-logo-img-1 calc(var(--speed) * 0.6) calc(var(--speed) * 0.4) forwards,
                loading-logo-img-1-rotate var(--speed) calc(var(--speed) * 1.3) infinite linear;

              @keyframes loading-logo-img-1 {
                to {
                  transform: scale(1);
                }
              }

              @keyframes loading-logo-img-1-rotate {
                to {
                  transform: scale(1) rotate(-90deg);
                }
              }
            }
          }
        }
      }
    }

    &.loaded {
      --speed: 2s;

      .header {
        animation: loaded-header calc(var(--speed) * 0.4) calc(var(--speed) * 0.5 + var(--speed) * 0.6) forwards;

        @keyframes loaded-header {
          to {
            min-height: 50px;
          }
        }

        &__logo {
          animation: loaded-logo var(--speed) calc(var(--speed) * 0.5) forwards;

          @keyframes loaded-logo {
            30% {
              transform: scale(1.5);
              width: 35px;
            }

            60% {
              transform: scale(1.5);
              width: 150px;
            }

            100% {
              transform: scale(1);
              width: 150px;
            }
          }

          .img-1 {
            svg {
              animation: loaded-logo-img-1-rotate calc(var(--speed) * 0.4) ease-out forwards;

              @keyframes loaded-logo-img-1-rotate {
                to {
                  transform: rotate(-45deg);
                }
              }
            }
          }
        }
      }
    }
  }

  &.fast-loader1 {
    .header {
      min-height: 100vh;

      &__logo {
        width: 35px;
        transform: scale(2.5);

        .img-1 {
          position: relative;
        }
      }

      &__prev {

      }

      &-categories {
        //transition: ;
        overflow: hidden;
      }
    }

    &.loading {
      --speed: 1s;

      .header {
        &__prev {
          opacity: 0;
          transform: translateX(-50%);
        }

        &-categories {
          overflow: hidden;
          max-height: 0;
        }

        &__logo {
          .img-1 {
            svg {
              transform: scale(0);
              will-change: transform;
              animation:
                loading-logo-img-1 calc(var(--speed) * 0.6) calc(var(--speed) * 0.4) forwards,
                loading-logo-img-1-rotate var(--speed) calc(var(--speed) * 1.3) infinite linear;

              @keyframes loading-logo-img-1 {
                to {
                  transform: scale(1);
                }
              }

              @keyframes loading-logo-img-1-rotate {
                to {
                  transform: scale(1) rotate(-90deg);
                }
              }
            }
          }
        }
      }
    }

    &.loaded {
      --speed: 2s;

      .header {
        animation: loaded-header calc(var(--speed) * 0.4) calc(var(--speed) * 0.5 + var(--speed) * 0.6) forwards;

        @keyframes loaded-header {
          to {
            min-height: 50px;
          }
        }

        &__logo {
          animation: loaded-logo var(--speed) calc(var(--speed) * 0.5) forwards;

          @keyframes loaded-logo {
            30% {
              transform: scale(1.5);
              width: 35px;
            }

            60% {
              transform: scale(1.5);
              width: 150px;
            }

            100% {
              transform: scale(1);
              width: 150px;
            }
          }

          .img-1 {
            svg {
              animation: loaded-logo-img-1-rotate calc(var(--speed) * 0.4) ease-out forwards;

              @keyframes loaded-logo-img-1-rotate {
                to {
                  transform: rotate(-45deg);
                }
              }
            }
          }
        }
      }
    }
  }
}
